var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'table-container': true,
    'table-responsive': _vm.responsivo
  }},[_c('table',{class:{
      'table': true
    },attrs:{"aria-label":_vm.titulo}},[_c('thead',[_c('tr',_vm._l((_vm.campos),function(campo,idx3){return _c('th',{key:`${_vm.id}-${campo.campo}`,attrs:{"scope":"col"}},[_c('div',{class:[{
                       'conteudo': true,
                       'ordenavel': campo.ordenavel},
                     _vm.alinhamentos[idx3]
            ],on:{"click":function($event){return _vm.ordenar(campo)}}},[_vm._v(" "+_vm._s(campo.nome)+" "),(campo.ordenavel)?_c('em',{class:_vm.obterIconeOrdenavel(campo)}):_vm._e()])])}),0)]),_c('tbody',_vm._l((_vm.dadosPaginados),function(dado,idx){return _c('tr',{key:`${_vm.id}-row-${idx}`,class:{
          'acionavel': _vm.clicavel,
          'selecionada': _vm.selecionada === idx
        },on:{"click":function($event){return _vm.handleClick(dado, idx)},"dblclick":function($event){return _vm.handleDblClick(dado, idx)}}},_vm._l((_vm.campos),function(campo,idx2){return _c('td',{key:`${_vm.id}-row-${idx}-col-${idx2}`,class:_vm.alinhamentos[idx2]},[(campo.slot)?_vm._t(campo.slot,null,{"data":dado}):(campo.campo !== 'acoes')?_c('span',[_vm._v(" "+_vm._s(campo.formato && typeof campo.formato === 'function' ? campo.formato(dado[campo.campo]) : dado[campo.campo])+" ")]):_vm._t("acoes",function(){return _vm._l((_vm.acoes),function(acao){return _c('span',{key:acao.id,staticClass:"mr-1"},[_c('uspBotao',{staticClass:"text-nowrap mb-1",attrs:{"texto":acao.titulo,"icone":acao.icone,"variante":acao.variante,"tamanho":acao.tamanho,"desabilitado":!_vm.tratarCondicao(acao.condicao, dado)},on:{"clicar":function($event){return _vm.selecionar(acao.titulo, dado)}}})],1)})},{"data":dado})],2)}),0)}),0)]),(_vm.paginacao)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',[_c('uspPaginacao',{attrs:{"icones":"","tamanho":"pq","paginas":_vm.paginas},on:{"paginar":_vm.paginar}})],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }